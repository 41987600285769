body .p-datatable .p-datatable-thead > tr > th {
  text-align: left;
}

body .p-datatable {
  font-size: 0.95em;
}

.p-datatable-tbody {
  font-size: 0.95em;
}

body .p-inputtext {
  font-size: 0.95em;
}
.carousel-demo
  .p-carousel
  .p-carousel-content
  .p-carousel-item
  .car-details
  > .p-grid {
  border-radius: 3px;
  margin: 0.3em;
  text-align: center;
  padding: 2em 0 2.25em 0;
}
.carousel-demo
  .p-carousel
  .p-carousel-content
  .p-carousel-item
  .car-data
  .car-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 24px;
}
.carousel-demo
  .p-carousel
  .p-carousel-content
  .p-carousel-item
  .car-data
  .car-subtitle {
  margin: 0.25em 0 2em 0;
}
.carousel-demo
  .p-carousel
  .p-carousel-content
  .p-carousel-item
  .car-data
  button {
  margin-left: 0.5em;
}
.carousel-demo
  .p-carousel
  .p-carousel-content
  .p-carousel-item
  .car-data
  button:first-child {
  margin-left: 0;
}

.dataview-demo .p-dataview .car-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
}
.dataview-demo .p-dataview .car-details > div {
  display: flex;
  align-items: center;
}
.dataview-demo .p-dataview .car-details > div img {
  margin-right: 14px;
}
.dataview-demo .p-dataview .car-detail {
  padding: 0 1em 1em 1em;
  margin: 1em;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.datatable-demo
  .p-datatable-tbody
  > tr.p-datatable-row
  > td:last-child
  .p-column-title {
  display: none;
}

/* ========= App Styles  ========= */

body {
  background: white;
}

.my-app {
  width: 98vw;
  height: 98vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#myGrid {
  flex: 1 1 0px;
  /* width: 100%; */
}

/* ========= Grid Styles  ========= */

.ag-center-cols-container .MuiInput-underline:before {
  display: none !important;
}

.ag-status-bar {
  background: #007bff;
  height: 60px;
}

.ag-input-field-input.ag-text-field-input {
  height: 40px;
  font-size: 1rem !important;
}

/* ========= Button Styles ========= */

button[variant][color] {
  border: none;
  color: white;
  padding: 5px;
  width: 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: white;
  color: black;
  border: 2px solid black;
}

button[variant][color]:focused {
  outline: none;
}

button[variant][color]:disabled {
  color: lightgrey !important;
  border-color: lightgrey !important;
  background: whitesmoke !important;
}

button[variant="outlined"][color="primary"] {
  color: dodgerblue;
  border-color: dodgerblue;
}

button[variant="contained"][color="primary"] {
  background-color: dodgerblue;
  border-color: dodgerblue;
  color: white;
}

button[variant="outlined"][color="secondary"] {
  color: #e91e63;
  border-color: #e91e63;
}

button[variant="contained"][color="secondary"] {
  background-color: #e91e63;
  border-color: #e91e63;
  color: white;
}

.ag-theme-balham {
  /* use theme parameters where possible */
  --ag-foreground-color: rgb(0, 0, 0);

  /* Background colour applied to every other row or null to use background-color for all rows */
  --ag-odd-row-background-color: rgb(245, 245, 245);

  /* Background color when hovering over rows in the grid and in dropdown menus, or null for no rollover effect (note - if you want a rollover on one but not the other, set to null and use CSS to achieve the rollover) */
  --ag-row-hover-color: rgb(109, 176, 238);
}

.available {
  background-color: rgb(226, 29, 193);
}

.sale-pending-warning {
  background-color: rgb(255, 145, 0) !important;
  color: rgb(0, 0, 0) !important;
}

.sick-days-breach {
  background-color: lightcoral !important;
}

.ag-column-hover {
  background-color: #e7dfba;
}

.card .p-rowgroup-footer td {
  font-weight: 900;
}

.card .p-rowgroup-header span {
  font-weight: 700;
}

.card .p-rowgroup-header .p-row-toggler {
  vertical-align: middle;
  margin-right: 0.25rem;
}

.filtersearch {
  font-size: large;
}

.search-input {
  font-size: medium;
  margin-left: 5px;
}
